<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="guiGeMC" label="规格名称" />
            <x-detail-item prop="shangJiGuiGe" label="上级规格" />
            <x-detail-item prop="guiShuMenDian" label="归属门店" />
            <x-detail-item prop="shiFuShanChu" label="是否删除" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/spgl/ShangPinGuiGe";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                guiGeMC: "", // 规格名称
                shangJiGuiGe: "", // 上级规格
                guiShuMenDian: "", // 归属门店
                shiFuShanChu: "", // 是否删除
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>