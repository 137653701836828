<template>
    <el-dialog :title="titlePrefix" :visible.sync="dialogVisible"
               :close-on-click-modal="false" destroy-on-close append-to-body width="1000px">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="规格名称">
                <el-input v-model="search.guiGeMC" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column prop="guiGeMC" label="规格名称"/>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)"
                               style="margin-right: 10px">编辑
                    </el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <xia-ji-edit ref="edit" :editor="editor" @submitted="refresh"/>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/spgl/ShangPinGuiGe";
    import XTableBase from "@/components/x/XTableBase";
    import xiaJiEdit from "./ShangPinGuiGeXiaJiEdit";


    export default {
        components: {xiaJiEdit},
        mixins: [XTableBase],
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                dialogVisible: false,
                shangJiGuiGe:'',
                search: {
                    guiGeMC: '',
                    shangJiGuiGe: ''
                }
            }
        },
        beforeCreate() {
            this.titlePrefix = '下级规格';
        },
        methods: {
            show(id) {
                this.dialogVisible = true
                this.shangJiGuiGe = id
                this.search.shangJiGuiGe = id
                this.refresh()
            },
            refresh() {
                this.table.loading = true;
                this.search.shangJiGuiGe = this.shangJiGuiGe
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            handleAdd() {
                this.editor.isUpdate = false;
                this.editor.id = null;
                this.editor.visible = true;
                this.$nextTick(()=>{
                    this.$refs.edit.show(this.shangJiGuiGe);
                })
            },
        },
    }
</script>

<style scoped>

</style>
