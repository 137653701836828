<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="规格名称">
                <el-input v-model="search.guiGeMC" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column prop="guiGeMC" label="规格名称"/>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleXiaJiGuiGe(row.id)">下级规格</el-button>
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)" style="margin-right: 10px">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <shang-pin-gui-ge-xia-ji-list ref="xiaJiList"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinGuiGe";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/spgl/spgg/ShangPinGuiGeEdit";
    import Detail from "@/view/spgl/spgg/ShangPinGuiGeDetail";
    import ShangPinGuiGeXiaJiList from "./ShangPinGuiGeXiaJiList";

    export default {
        name: "ShangPinGuiGeList",
        mixins: [XTableBase],
        components: {Edit, Detail,ShangPinGuiGeXiaJiList},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    guiGeMC: '',
                }
            }
        },
        methods:{
            handleXiaJiGuiGe(id){
                this.$nextTick(()=>{
                    this.$refs.xiaJiList.show(id);
                })
            },
        }
    }
</script>

<style scoped>
</style>
