import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/shang-pin-gui-ge/list',
        method: 'post',
        data
    })
}

//商品规格选择器查询接口
export function getGuiGeList() {
    return request({
        url: '/shang-pin-gui-ge/get-select-list',
        method: 'get',
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/shang-pin-gui-ge/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/shang-pin-gui-ge/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/shang-pin-gui-ge/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/shang-pin-gui-ge/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/shang-pin-gui-ge/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/shang-pin-gui-ge/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/shang-pin-gui-ge/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'shang-pin-gui-ge-list',
    component: () => import('@/view/spgl/spgg/ShangPinGuiGeList'),
    name: 'ShangPinGuiGeList',
    meta: {title: '商品规格', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'shang-pin-gui-ge-query',
    component: () => import('@/view/spgl/spgg/ShangPinGuiGeQuery'),
    name: 'ShangPinGuiGeQuery',
    meta: {title: '商品规格查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'shang-pin-gui-ge-remind-list',
    component: () => import('@/view/spgl/spgg/ShangPinGuiGeRemindList'),
    name: 'ShangPinGuiGeRemindList',
    meta: {title: '商品规格提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"商品规格基础列表","methodUrl":"/shang-pin-gui-ge/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"商品规格添加保存","methodUrl":"/shang-pin-gui-ge/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"商品规格获取编辑数据","methodUrl":"/shang-pin-gui-ge/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"商品规格获取详情数据","methodUrl":"/shang-pin-gui-ge/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"商品规格编辑保存","methodUrl":"/shang-pin-gui-ge/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"商品规格删除","methodUrl":"/shang-pin-gui-ge/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"商品规格批量删除","methodUrl":"/shang-pin-gui-ge/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"商品规格独立查询","methodUrl":"/shang-pin-gui-ge/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
